import React, {Component} from 'react';
import {Modal, ModalBody} from "reactstrap"
import styled from "styled-components"
import {media} from "utils/Media"
import {graphql, StaticQuery} from "gatsby";

const VModal = styled(Modal)`
    max-width: 580px;
    padding: 0;
    border: 0;
    //background: #005F91;
    //opacity: 0.7;

    .modal-content {
        border: 0;
        position: relative;
        border-radius: 0;
    }

    .modal-body {
        padding: 0.5rem;
        border: 0;
        
        @media ${media.sm} {
            padding: 0;
        }
    }
    
    h3 {
        font-family: ${props => props.theme.font.family.special};
        color: ${props => props.theme.colors.navy};
        margin: 1rem 0 1rem;
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        text-align: center;   
        font-size: 24px;
        line-height: 100%;
        
        @media ${media.sm} {
            font-size: 30px;
            margin: 2rem 0 1rem;
        }
    }
`

const Table = styled.table`
  width: 95%;
  max-width: 740px;
  margin: 2rem auto;
  tr {
    &:nth-of-type(even) {
      background-color: #effbfd;
    }
  }
  @media ${media.sm} {
    padding: 0 2rem;
    margin: 2rem auto;
  }
  th {
    @media ${media.md} {
      padding: 0.85rem;
    }
  }
  th,
  td {
    border-right: 1px solid #003199;
    &:nth-last-child(1) {
      border-right: none;
    }
  }
  th,
  td {
    text-align: center;
  }
  td {
    padding: 0.85rem;
  }
  tr {
    &:first-of-type {
      background-color: #effbfd;

      border-bottom: 1px solid #003199;
    }
    &:nth-of-type(odd) {
      &:first-of-type {
        background-color: rgba(121, 246, 240, 0.5);
      }
    }
  }
  @media ${media.md} {
    font-size: 1rem;
  }
`;
const TableRow = styled.tr`
  .tableRow-helper {
    width: 100%;
    display: flex;
    border-top: 1px solid #003199;
  }

  .tableCol {
    width: 50%;
    padding: 0.5rem 1rem;
    font-weight: bold;
  }

  &:nth-of-type(odd) {
    .tableCol:first-of-type {
      background-color: rgba(121, 246, 240, 0.5);
    }
  }

  &:nth-of-type(even) {
    .tableCol:first-of-type {
      background-color: #effbfd;
    }
  }

  &:last-of-type {
    border-bottom: 1px solid #003199;
  }
`;

const ButtonClose = styled.button`
    position: absolute;
    background-color: transparent;
    z-index: 10;
    right: 1rem;
    top: 1rem;
    width: 20px;
    height: 20px;
    border: 2px solid #81E0FF;

    @media (min-width: 992px) {
        width: 30px;
        height: 30px;
        border: 2px solid #fff;
        right: -2rem;
        top: -2rem;
    }
    
    &:before{
        content: "";
        position: absolute;
        width: 80%;
        height: 2px;
        background-color: #81E0FF;
        transform: translate(-50%, -50%) rotate(-45deg);
        
        @media (min-width: 992px) {
            background-color: #fff;
        }
    }
    
    &:after{
        content: "";
        position: absolute;
        width: 80%;
        height: 2px;
        background-color: #81E0FF;
        transform: translate(-50%, -50%) rotate(45deg);
        
        @media (min-width: 992px) {
            background-color: #fff;
        }
    }

`

const Query = (props) => (
    <StaticQuery
        query={graphql`
            query {
                allPreviewScheduleJson {
                    edges {
                        node {
                            date
                            time
                        }
                    }
                }
            }
		`}
        render={data => (
            <>
                <PreviewModal modalOpen={props.modalOpen} toggleModal={props.toggleModal} data={data}/>
            </>
        )}
    />
)

class PreviewModal extends Component {
    render() {
        // const dates = this.props.data.allPreviewScheduleJson.edges.map(({node}, i) => {
        //     return (

        //         <TableRow
        //             key={i}
        //         >
        //             <div className="tableCol">{node.date}</div>
        //             <div className="tableCol">{node.time}</div>
        //         </TableRow>
        //     )
        // })

        return (
            <VModal isOpen={this.props.modalOpen} toggle={this.props.toggleModal} centered={true}>
                <ButtonClose onClick={this.props.toggleModal}>
                    <span className="sr-only">Close</span>
                </ButtonClose>
                <ModalBody>
                    <h3 className="d-none d-sm-block">Education Groups 10+:</h3>
                    <p className="text-center">One free teacher for every 10 pupils booked.</p>
          <Table>
            <tr>
              <th>Ticket price</th>
              <th>Group rate</th>
              <th>Early Booker rate*</th>
            </tr>
            <tr>
              <td>Stalls/Royal Circle</td>
              <td>£29.50</td>
              <td>£25</td>
            </tr>
            <tr>
              <td>Grand Circle</td>
              <td>£22.50</td>
              <td>£19.50</td>
            </tr>
           
          </Table>
                </ModalBody>
            </VModal>
        );
    }
}

export default Query;